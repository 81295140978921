import React, { useEffect } from "react";
import RouteSwitch from "./RouteSwitch";
import { Detector } from "react-detect-offline";
import OfflinePage from "pages/OfflinePage";
import IncompatiblePage from "pages/IncompatiblePage";
import { checkVersion } from "./checkVersion";
import IntroPage from "pages/IntroPage";

const webGLCompatible = () => {
  const canvas = document.createElement("canvas");
  const gl =
    canvas.getContext("webgl") || canvas.getContext("experimental-webgl");
  if (gl && gl instanceof WebGLRenderingContext) {
    return true;
  } else {
    return false;
  }
};

const App: React.FC = () => {
  useEffect(() => {
    checkVersion();
  }, []);

  return (
    <div className="h-full">
      <IntroPage />
      {webGLCompatible() ? (
        <Detector
          polling={{
            interval: 20000,
          }}
          render={({ online }) => (
            <>
              {!online && <OfflinePage />}
              {online && <RouteSwitch />}
            </>
          )}
        />
      ) : (
        <IncompatiblePage />
      )}
    </div>
  );
};

export default App;
