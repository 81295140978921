import Header from "components/layout/Header/Header";
import Typography from "components/ui/typography/Typography";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import { useHistory } from "react-router-dom";
import { splitLanguage } from "resources/functions/splitLanguage";

const ImpressumPage: React.FC = () => {
  const { i18n, t } = useTranslation();
  const { push } = useHistory();
  const [data, setData] = useState([]);

  const getContentJSON = useCallback(async () => {
    const res = await fetch(
      `${process.env.PUBLIC_URL}/${process.env.REACT_APP_CONTENT_FOLDER}/imprint.json`
    );
    const data = await res.json();
    setData(data);
  }, []);

  useEffect(() => {
    getContentJSON();
  }, [getContentJSON]);

  return (
    <>
      <div className="p-4">
        <Header shade="dark" onCloseAction={() => push("/")} />
        <div className="h-full p-2 bg-white pt-28">
          <Typography
            paragraph
            variant="h6"
            color="text-primary-main"
            className="font-semibold"
          >
            {t("pages.impressum")}
          </Typography>
          <div>
            {data.map((section, i) => (
              <div key={i}>
                <ReactQuill
                  readOnly
                  value={section[splitLanguage(i18n.language)] || section.de}
                  modules={{
                    toolbar: null,
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <style>{`
      .ql-editor{
        color:#63378D;
      }
      .ql-clipboard{
        display:none;
      }
      `}</style>
    </>
  );
};

export default ImpressumPage;
