import { mdiPlay } from "@mdi/js";
import Icon from "@mdi/react";
import clsx from "clsx";
import Loader from "components/ui/loader/Loader";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player/lazy";
import { useQuery } from "react-query";
import ReactQuill from "react-quill";
import { splitLanguage } from "resources/functions/splitLanguage";
import { ContentService } from "services/ContentService";
import { IDataArray } from "../IData.interface";

interface Props {
  id: string;
  videoName: string; // cames already calculated with correct language
  mediaPoster: IDataArray["mediaPoster"];
  mediaCaption: IDataArray["mediaCaption"];
}

export const VideoPlayer: React.FC<Props> = ({
  id,
  videoName,
  mediaPoster,
  mediaCaption,
}: Props) => {
  const [playing, setPlaying] = useState(false);
  const [showPlayButton, setShowPlayButton] = useState(true);
  const [playBool, setPlayBool] = useState(false);
  const { i18n } = useTranslation();

  const { data: videoUrl, isLoading } = useQuery(
    [id, videoName],
    () => ContentService.getVideo({ id, videoName })
    // { enabled: false }
  );

  if (isLoading) {
    return (
      <div className="flex items-center justify-center p-28">
        <Loader />
      </div>
    );
  }

  return (
    <div className="py-2">
      <div className="relative flex flex-col items-center justify-center bg-black">
        <div className="flex justify-center " style={{ maxWidth: "100%" }}>
          <ReactPlayer
            key={videoName}
            config={{
              file: {
                attributes: {
                  poster: `${process.env.PUBLIC_URL}/${process.env.REACT_APP_CONTENT_FOLDER}/${id}/${mediaPoster}`,
                  preload: "none",
                  style: { maxHeight: "80vh", width: "100%", height: "auto" },
                  crossOrigin: "anonymous",
                },
              },
            }}
            playing={playBool}
            url={videoUrl}
            // url={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_CONTENT_FOLDER}/${id}/${videoName}`}
            controls={playBool}
            width="100%"
            height="auto"
            style={{ maxWidth: "100%" }}
            onPlay={() => {
              setPlaying(true);
            }}
            onPause={() => setPlaying(false)}
          />
        </div>
        {mediaCaption && (
          <div className="z-10 w-full px-6 py-3">
            <ReactQuill
              className="absolute z-10 text-white caption bottom-5 left-10"
              readOnly
              value={mediaCaption[splitLanguage(i18n.language) || "de"]}
              modules={{
                toolbar: null,
              }}
            />
          </div>
        )}

        {!playing && (
          <div
            className={clsx(
              "absolute top-0 left-0 w-full h-full",
              !showPlayButton && "pointer-events-none"
            )}
            style={{
              background:
                "linear-gradient(180deg, rgba(255,255,255,0) 0%,rgba(71,60,159,0.49) 100%)",
            }}
          >
            {showPlayButton && (
              <div
                className="flex items-center justify-center w-full h-full text-white"
                onClick={() => {
                  setPlayBool(true);
                  setShowPlayButton(false);
                }}
              >
                <Icon path={mdiPlay} size={2} />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
