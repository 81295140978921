import { IData } from "../QuizData.interface";
import styled from "styled-components";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { splitLanguage } from "resources/functions/splitLanguage";

import Title from "components/ui/typography/Title";
import Subtitle from "components/ui/typography/Subtitle";

interface Props {
    data: IData;
}

const Quiz = styled.div`
    padding-bottom: 1.5rem;
    .options {
        padding-top: 1rem;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        button {
            color: #fff;
            border: 2px solid #fff;
            padding: .5em;
            display: flex;
            align-items: center;
            gap: 1em;
            text-align: left;
        }
    }
    .answerIndicator {
        height: 1.5em;
        width: 1.5em;
        min-width: 1.5em;
        border: 1px solid #fff;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .answer {
        color: #fff;
        header {
            display: flex;
            gap: .5em;
            align-items: center;
            font-size: 2em;
            padding: 1rem 0;
        }
        button {
            margin-top: 2em;
            display: inline-block;
            padding: .5em 1em;
            background: #fff;
            border-radius: .25em;
        }
    }
`

const QuizContent: React.FC<Props> = ({ data }: Props) => {
    const { i18n } = useTranslation();
    const [currentQuestion, setCurrentQuestion] = useState(1);
    const [showAnswer, setShowAnswer] = useState(null);

    return (
        <Quiz>
            {data.title && (
                <Title
                    color={
                        data.backgroundColor === "dark"
                        ? "text-white"
                        : "text-primary-main"
                    }
                    className="py-3 px-6 pt-28"
                >
                    <span dangerouslySetInnerHTML={{__html: data.title[splitLanguage(i18n.language)] || data.title.de}} />
                </Title>
            )}
            {currentQuestion && (
                <>
                    {showAnswer !== null && (
                        <div className="answer px-6">
                            <header>
                                {data.dataArray[currentQuestion-1].correctAnswer.map((n,i) => (
                                    <div key={i} className="answerIndicator">{String.fromCharCode(65 + n)}</div>
                                ))}
                                {data.dataArray[currentQuestion-1].correctAnswer.includes(showAnswer) ? (
                                    data.rightAnswer[splitLanguage(i18n.language)] || data.rightAnswer.de
                                ) : (
                                    data.wrongAnswer[splitLanguage(i18n.language)] || data.wrongAnswer.de
                                )}
                            </header>
                            <p dangerouslySetInnerHTML={{__html: data.dataArray[currentQuestion-1].explanation[splitLanguage(i18n.language)] || data.dataArray[currentQuestion-1].explanation.de}} />
                            {currentQuestion === data.dataArray.length ? (
                                <button 
                                    className="text-primary-main"
                                    onClick={() => {
                                        setCurrentQuestion(1)
                                        setShowAnswer(null)
                                    }}
                                >{data.restartButton[splitLanguage(i18n.language)] || data.restartButton.de}</button>
                            ) : (
                                <button 
                                    className="text-primary-main"
                                    onClick={() => {
                                        setCurrentQuestion(currentQuestion+1)
                                        setShowAnswer(null)
                                    }}
                                >{data.nextButton[splitLanguage(i18n.language)] || data.nextButton.de}</button>
                            )}
                        </div>
                    )}
                    {showAnswer === null && (
                        <div className="question px-6">
                            <Subtitle
                                className="py-3"
                                color={
                                data.backgroundColor === "dark"
                                    ? "text-white"
                                    : "text-primary-main"
                                }
                            >
                                <span dangerouslySetInnerHTML={{__html: data.dataArray[currentQuestion-1].question[splitLanguage(i18n.language)] || data.dataArray[currentQuestion-1].question.de}} />
                            </Subtitle>
                            <div className="options">
                                {data.dataArray[currentQuestion-1].answers.map((answer, i) => (
                                    <button key={i} onClick={() => {setShowAnswer(i)}}>
                                        <div className="answerIndicator">{String.fromCharCode(65 + i)}</div>
                                        <span dangerouslySetInnerHTML={{__html: answer[splitLanguage(i18n.language)] || answer.de}} />
                                    </button>
                                ))}
                            </div>
                        </div>
                    )}
                </>
            )}
        </Quiz>
    )
};

export default QuizContent;