import { mdiClose, mdiMenu } from "@mdi/js";
import Icon from "@mdi/react";
import Typography from "components/ui/typography/Typography";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import clsx from "clsx";
import ClickAwayListener from "react-click-away-listener";
import IconButton from "components/ui/buttons/IconButton";
import maxplank from "../../../resources/images/maxplank_small.svg";
import goethe from "../../../resources/images/goethe_small.svg";
import maxplankColored from "../../../resources/images/maxplank_small_colored.svg";
import goetheColored from "../../../resources/images/goethe_small_colored.svg";
import { currentVersion } from "app/checkVersion";
import { LanguageSwitcher } from "components/utils/LanguageSwitcher";

interface IHeader {
  shade?: "dark" | "light";
  onCloseAction?: () => void;
  logoOnly?: boolean;
}

const Header: React.FC<IHeader> = ({
  shade = "light",
  onCloseAction,
  logoOnly,
}: IHeader) => {
  const { t } = useTranslation();
  const [showMenu, setShowMenu] = useState(false);

  const Logo = ({ src }: { src: string }) => (
    <img src={src} alt="logo" style={{ maxWidth: 56 }} className="p-1" />
  );

  return (
    <div className="absolute top-0 left-0 z-50 w-full">
      <div className="flex justify-between p-3">
        <div className="flex p-2 space-x-3">
          {shade === "light" && (
            <>
              <Logo src={goethe} />
              <Logo src={maxplank} />
            </>
          )}
          {shade === "dark" && (
            <>
              <Logo src={goetheColored} />
              <Logo src={maxplankColored} />
            </>
          )}
        </div>
        {!logoOnly && (
          <div className="flex items-center">
            <div className="flex flex-col">
              <LanguageSwitcher shade={shade} />
            </div>
            {!onCloseAction && (
              <IconButton onClick={() => setShowMenu(true)}>
                <Icon
                  path={mdiMenu}
                  size={1}
                  className={clsx(
                    shade === "light" && "text-white",
                    shade === "dark" && "text-primary-main"
                  )}
                />
              </IconButton>
            )}
            {onCloseAction && (
              <IconButton onClick={onCloseAction}>
                <Icon
                  path={mdiClose}
                  size={1}
                  className={clsx(
                    shade === "light" && "text-white",
                    shade === "dark" && "text-primary-main"
                  )}
                />
              </IconButton>
            )}
          </div>
        )}
      </div>
      {showMenu && (
        <ClickAwayListener onClickAway={(e) => setShowMenu(false)}>
          <div
            className={clsx(
              "flex flex-col pt-4",
              shade === "light" && "bg-primary-main",
              shade === "dark" && "bg-white"
            )}
          >
            <Link to={"/impressum"}>
              <button
                className={clsx(
                  "py-8 border-b border-white w-full hover:bg-opacity-20 hover:bg-white border-t"
                )}
                onClick={() => setShowMenu(false)}
              >
                <Typography
                  variant="h6"
                  color="text-white"
                  className="font-semibold text-center signika"
                >
                  {t("pages.impressum")}
                </Typography>
              </button>
            </Link>

            <a
              href="https://www.goethe.de/de/dat.html"
              target="_blank"
              rel="noreferrer"
            >
              <button
                className={clsx(
                  "py-8 border-b border-white w-full hover:bg-opacity-20 hover:bg-white"
                )}
                onClick={() => setShowMenu(false)}
              >
                <Typography
                  variant="h6"
                  color="text-white"
                  className="font-semibold text-center signika"
                >
                  {t("pages.privacy")}
                </Typography>
              </button>
            </a>
            <div className="flex justify-end p-2 text-white opacity-20">
              <Typography>{currentVersion()}</Typography>
            </div>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default Header;
