import IconButton from "components/ui/buttons/IconButton";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "resources/functions/changeLanguage";
import { useQuery } from "react-query";
import { LanguageService } from "services/LanguageService";
import Icon from "@mdi/react";
import { mdiChevronDown } from "@mdi/js";
import { Menu, Transition } from "@headlessui/react";
import clsx from "clsx";
import { splitLanguage } from "resources/functions/splitLanguage";

interface Props {
  shade?: "dark" | "light";
}

export const LanguageSwitcher: React.FC<Props> = ({ shade }) => {
  const { i18n } = useTranslation();

  const { data } = useQuery("language", LanguageService.getLanguageData);

  return (
    <Menu>
      <Menu.Button>
        <div
          className={clsx(
            "p-2 hover:bg-opacity-20 hover:bg-white rounded-full h-12 w-12 flex justify-center items-center focus:outline-none focus-within:outline-none",
            shade === "light" && "text-white",
            shade === "dark" && "text-primary-main"
          )}
        >
          <div className="flex flex-col items-center justify-between h-full pt-2">
            <span
              className="font-semibold signika"
              style={{ fontSize: 20, lineHeight: 0.7 }}
            >
              {splitLanguage(i18n.language).toUpperCase()}
            </span>
            <Icon path={mdiChevronDown} size={0.65} />
          </div>
        </div>
      </Menu.Button>
      <Transition
        enter="transition duration-50 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-25 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Menu.Items
          as="ul"
          className="absolute list-none focus:outline-none focus-within:outline-none"
        >
          {data?.supportedLanguages.length > 0 &&
            data.supportedLanguages.map(
              (lang, i) =>
                splitLanguage(i18n.language) !== lang && (
                  <Menu.Item
                    key={i}
                    as="li"
                    className="focus:outline-none focus-within:outline-none"
                  >
                    <IconButton
                      onClick={(): void =>
                        changeLanguage(lang, data.rtlLanguages.includes(lang))
                      }
                      shade={shade}
                    >
                      <span
                        className="font-semibold signika"
                        style={{ fontSize: 20 }}
                      >
                        {lang.toUpperCase()}
                      </span>
                    </IconButton>
                  </Menu.Item>
                )
            )}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
