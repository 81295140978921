import clsx from "clsx";
import Header from "components/layout/Header/Header";
import ChevronButton from "components/ui/buttons/ChevronButton";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useEventListener } from "resources/hooks/useEventListener";
import ARContent from "./components/ARContent";
import MediaContent from "./components/MediaContent";
import GameContent from "./components/GameContent";
import QuizContent from "./components/QuizContent";

const absoluteShowScroll = () => {
  const innerHeight = window.innerHeight;
  const totalHeight = Math.max(
    document.body.scrollHeight,
    document.body.offsetHeight
  );
  if (innerHeight * 2 < totalHeight) {
    return true;
  } else {
    return false;
  }
};

export const ContentBody: React.FC<any> = ({ data, id }) => {
  const { push } = useHistory();
  const [showScrollDownButton, setShowScrollDownButton] = useState(false);

  const scrollUp = useCallback(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const scrollDown = useCallback(() => {
    window.scrollTo({
      // top: document.body.scrollHeight,
      top: window.innerHeight,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    setShowScrollDownButton(absoluteShowScroll());
  }, [data]);

  useEventListener("scroll", () => {
    if (window.scrollY < 30 && absoluteShowScroll()) {
      setShowScrollDownButton(true);
    } else {
      setShowScrollDownButton(false);
    }
  });

  return (
    <>
      <Header
        onCloseAction={() => push("/camera")}
        shade={data.backgroundColor === "dark" ? "light" : "dark"}
      />
      <div
        className={clsx(
          data.contentType === "media" && "pt-28",
          "min-h-full ",
          data.backgroundColor === "dark" ? "bg-primary-main" : "white"
        )}
      >
        {data.contentType === "media" && <MediaContent id={id} data={data} />}
        {data.contentType === "ar" && <ARContent id={id} data={data} />}
        {data.contentType === "game" && <GameContent id={id} data={data} />}
        {data.contentType === "quiz" && <QuizContent data={data} />}

        {data.contentType === "media" && absoluteShowScroll() && (
          <div className="flex justify-center">
            <ChevronButton
              shade={data.backgroundColor === "dark" ? "light" : "dark"}
              onClick={scrollUp}
            />
          </div>
        )}
      </div>
      {showScrollDownButton && data.contentType === "media" && (
        <div
          className="sticky bottom-0 left-0 flex justify-center w-full"
          style={{
            background:
              "linear-gradient(180deg, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%)",
          }}
        >
          <ChevronButton
            shade={data.backgroundColor === "dark" ? "light" : "dark"}
            down
            onClick={scrollDown}
          />
        </div>
      )}
    </>
  );
};
