import { IData } from "../GameData.interface";
import styled from "styled-components";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { splitLanguage } from "resources/functions/splitLanguage";

import Title from "components/ui/typography/Title";
import IconButton from "components/ui/buttons/IconButton";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";

interface Props {
    id: string;
    data: IData;
}

const GameList = styled.div`
    ul {
        list-style: none;
        padding-bottom: 1.5rem;
    }
    li {
        aspect-ratio: 5 / 2;
        min-height: 120px;
        &.is-flipped {
            .flipbox {
                transform: perspective(400px) rotateY(180deg);
            }
        }
    }
    .flipbox {
        position: relative;
        height: 100%;
        transition: transform 600ms cubic-bezier(.4,0,.2,1);
        transform-style: preserve-3d;
    }
    &.has-flipped-item {
        position: absolute;
        h4, ul, li {
            position: absolute;
        }
        li {
            .back .content {
                opacity: 1;
            }
            &.is-flipped {
                height: 100dvh;
                width: 100vw;
                z-index: 100;
            }
        }
    }
    .front, .back {
        position: absolute;
        width: 100%;
        height: 100%;
        backface-visibility: hidden;
    }
    .front {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0 1.5rem;
        cursor: pointer;
        background-size: cover;
        background-position: 50% 50%;
        background-image: var(--jpgImage);
        @supports (background-image: var(--webpImage)) {
            background-image: var(--webpImage);
          }
        h3 {
            display: inline;
            font-size: 1rem;
            padding: 0 .5em;
            background: #fff;
            text-transform: uppercase;
        }
    }
    .back {
        transform: perspective(400px) rotateY(180deg);
        padding: 1rem 1.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        
        position: relative;
        background: #fff;
        button {
            position: absolute;
            top: .2rem;
            right: 1rem;
        }
        .content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: .5rem;
            overflow-y: scroll;
            opacity: 0;
            transition: opacity 300ms ease;
        }
        h3 {
            display: inline;
            font-size: 1rem;
            padding: 0 .5em;
            color: #fff;
            background: #000;
            text-transform: uppercase;
        }
    }
`

const GameContent: React.FC<Props> = ({ data, id }: Props) => {
    const { i18n } = useTranslation();
    const [flippedItem, setFlippedItem] = useState(null);
    const [childrenHasFlipped, setChildrenHasFlipped] = useState(null);
    const [flippedBack, setFlippedBack] = useState(false);

    const showBack = (item) => {
        setFlippedItem(item);
        setTimeout(() => {
            setChildrenHasFlipped(item);
        }, 600);
    }
    const showFront = () => {
        setChildrenHasFlipped(null);
        setFlippedBack(true);
        setTimeout(() => {
            setFlippedItem(null);
        }, 600);
    }

    const [scrollPosition, setScrollPosition] = useState(0);
    const updatePosition = () => {
        const position = window.scrollY;
        if(flippedItem === null) {
            setScrollPosition(position);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', updatePosition, { passive: true });
        if(flippedBack) {
            window.scrollTo(0, scrollPosition)
            setFlippedBack(false);
        }
        return () => {
            window.removeEventListener('scroll', updatePosition);
        };
    }, [scrollPosition, flippedItem, flippedBack]);

    return (
        <GameList className={`${childrenHasFlipped !== null ? 'has-flipped-item' : ''}`}>
            <Title
                color={
                    data.backgroundColor === "dark"
                    ? "text-white"
                    : "text-primary-main"
                }
                className="py-3 px-6 pt-28 break-words"
            >
                <span dangerouslySetInnerHTML={{__html: data.title[splitLanguage(i18n.language)] || data.title.de}} />
            </Title>
            <ul>
                {data.dataArray.map(
                    (
                        {
                            number,
                            unit,
                            title,
                            description,
                            mediaPoster,
                            mediaCaption,
                            mediaCopyRight
                        },
                        i
                    ) => (
                        <li key={i} className={`${flippedItem == i ? 'is-flipped' : ''}`}>
                            <div className="flipbox">
                                <div 
                                    className="front" 
                                    style={
                                        {
                                            ['--jpgImage' as string]: `url(${mediaPoster}.jpg)`, 
                                            ['--webpImage' as string]: `url(${mediaPoster}.webp)`
                                        }
                                    }
                                    onClick={() => {showBack(i)}}
                                >
                                    <h2 className="font-semibold signika leading-none text-white">
                                        {number[splitLanguage(i18n.language)] || number.de}
                                    </h2>
                                    {unit && (
                                        <h3>{unit[splitLanguage(i18n.language)] || unit.de}</h3>
                                    )}
                                </div>
                                <div className="back">
                                    <IconButton onClick={() => {showFront()}}>
                                        <Icon
                                            path={mdiClose}
                                            size={1}
                                            className="text-primary-main"
                                        />
                                    </IconButton>
                                    <div className="content">
                                        <img
                                            src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_CONTENT_FOLDER}/${id}/${mediaPoster}.jpg`}
                                            alt={mediaCaption[splitLanguage(i18n.language)] || mediaCaption.de}
                                            className="w-full"
                                        />
                                        {mediaCopyRight && (
                                            <p>&copy; {mediaCopyRight}</p>
                                        )}
                                        <h3 className="mt-8">{title[splitLanguage(i18n.language)] || title.de}</h3>
                                        <p dangerouslySetInnerHTML={{__html: description[splitLanguage(i18n.language)] || description.de}} />
                                    </div>
                                </div>
                            </div>
                        </li>
                    )
                )}
            </ul>
        </GameList>
    )
};

export default GameContent;