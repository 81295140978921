import maxplank from "../resources/images/maxplank_big.svg";
import goethe from "../resources/images/goethe_big.svg";
import { useEffect, useState } from "react";

const IntroPage: React.FC = () => {
  const [showIntro, setShowInto] = useState(
    process.env.NODE_ENV === "development" ? false : true
  );

  useEffect(() => {
    setTimeout(() => {
      setShowInto(false);
    }, 3000);
  }, []);

  if (!showIntro) {
    return null;
  }

  return (
    <div
      className="fixed top-0 bottom-0 left-0 right-0"
      style={{ zIndex: 99999 }}
    >
      <div className="flex flex-col items-center justify-center h-full space-x-5 bg-primary-main">
        <img
          src={goethe}
          alt="logo"
          style={{ maxWidth: 200, opacity: 0 }}
          className="animate-fade"
        />
        <img
          src={maxplank}
          alt="logo"
          style={{ maxWidth: 200, opacity: 0 }}
          className="animate-fade"
        />
      </div>
    </div>
  );
};

export default IntroPage;
