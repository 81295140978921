import Header from "components/layout/Header/Header";
import Typography from "components/ui/typography/Typography";
import { useTranslation } from "react-i18next";

const IncompatiblePage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col justify-end h-full bg-primary-main">
      <Header logoOnly />
      <div className="p-4 pb-10">
        <div className="flex flex-col items-center pb-6">
          <Typography
            variant="h6"
            color="text-white"
            className="font-semibold text-center signika"
          >
            {t("incompatible.warning")}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default IncompatiblePage;
