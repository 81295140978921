import { mdiWifiOff } from "@mdi/js";
import Icon from "@mdi/react";
import Header from "components/layout/Header/Header";
import Typography from "components/ui/typography/Typography";
import { useTranslation } from "react-i18next";
// import wifi from "../resources/images/wifi.svg";

const OfflinePage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col justify-end h-full bg-primary-main">
      <Header logoOnly />
      <div className="p-4 pb-10">
        <div className="flex flex-col items-center pb-6">
          {/* <img src={wifi} alt="wifi" className="m-10" /> */}
          <Icon path={mdiWifiOff} size={4} color="white" />
          <Typography
            variant="h6"
            color="text-white"
            className="font-semibold text-center signika"
          >
            {t("offline.warning")}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default OfflinePage;
