import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import { splitLanguage } from "resources/functions/splitLanguage";
import { VideoLangSwitcher } from "./VideoLangSwitcher";

export const MediaObject: React.FC<any> = ({
  contentType,
  mediaName,
  videoName,
  mediaPoster,
  mediaCaption,
  i,
  id,
}) => {
  const { i18n } = useTranslation();
  return (
    <>
      {contentType === "image" && (
        <div className="py-2">
          <div className="flex justify-center bg-black">
            <div className="relative">
              <img
                alt={mediaName || "dataImage"}
                src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_CONTENT_FOLDER}/${id}/${mediaName}`}
                className="w-full"
              />
              <div
                className="absolute top-0 left-0 w-full h-full"
                style={{
                  background:
                    "linear-gradient(180deg, rgba(255,255,255,0) 0%,rgba(71,60,159,0.49) 100%)",
                }}
              />
              {mediaCaption && (
                <ReactQuill
                  key={"quill" + i + i18n.language}
                  className="absolute z-10 text-white caption bottom-5 left-10"
                  readOnly
                  value={
                    mediaCaption[splitLanguage(i18n.language)] ||
                    mediaCaption.de
                  }
                  modules={{
                    toolbar: null,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      )}

      <VideoLangSwitcher
        lang={splitLanguage(i18n.language)}
        data={{
          contentType,
          mediaName,
          videoName,
          mediaPoster,
          mediaCaption,
          i,
          id,
        }}
      />
    </>
  );
};
