import clsx from "clsx";
import { ReactNode } from "react";
import { Link } from "react-router-dom";

interface IIconButton {
  children: ReactNode;
  link?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  shade?: "light" | "dark";
  title?: string;
}

const IconButton: React.FC<IIconButton> = ({
  children,
  link,
  onClick,
  shade = "light",
  title,
}: IIconButton) => {
  const Button = () => (
    <button
      title={title}
      onClick={onClick}
      className={clsx(
        "p-2 hover:bg-opacity-20 hover:bg-white rounded-full h-12 w-12 flex justify-center items-center focus:outline-none focus-within:outline-none",
        shade === "light" && "text-white",
        shade === "dark" && "text-primary-main"
      )}
    >
      {children}
    </button>
  );

  return link ? (
    <Link to={`${link}`}>
      <Button />
    </Link>
  ) : (
    <Button />
  );
};

export default IconButton;
