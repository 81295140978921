import { mdiChevronDown, mdiChevronUp } from "@mdi/js";
import Icon from "@mdi/react";
import clsx from "clsx";

interface Props {
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  shade?: "dark" | "light";
  down?: boolean;
}

const ChevronButton: React.FC<Props> = ({
  onClick,
  shade = "light",
  down,
}: Props) => {
  return (
    <div
      style={{
        height: 70,
        width: 70,
      }}
      onClick={onClick}
    >
      <div
        className={clsx(
          "p-3 justify-center items-center h-full flex",
          shade === "light" && "bg-white",
          shade === "dark" && "bg-primary-main"
        )}
      >
        <Icon
          path={down ? mdiChevronDown : mdiChevronUp}
          size={1}
          className={clsx(
            shade === "light" && "text-primary-main",
            shade === "dark" && "text-white"
          )}
        />
      </div>
    </div>
  );
};

export default ChevronButton;
