import SwipeContainer from "app/SwipeContainer";
import Header from "components/layout/Header/Header";
import ChevronButton from "components/ui/buttons/ChevronButton";
import Typography from "components/ui/typography/Typography";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const HomePage: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <SwipeContainer>
        <div className="flex flex-col justify-end h-full bg-primary-main">
          <div className="p-4 pb-10">
            <div className="pb-6 border-b border-white">
              <Typography
                variant="h5"
                color="text-white"
                className="font-bold uppercase signika"
                style={{
                  wordSpacing: "100vw",
                  lineHeight: 1.2,
                }}
              >
                {t("home.content.text.main")}
              </Typography>
            </div>
          </div>
          <div className="flex justify-center">
            <ChevronButton onClick={() => history.push("/camera")} />
          </div>
        </div>
      </SwipeContainer>
    </>
  );
};

export default HomePage;
