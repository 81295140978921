import CameraPage from "pages/Camera";
import ContentPage from "pages/contentPage/ContentPage";
import { ContentRouter } from "pages/contentPage/ContentRouter";
import HomePage from "pages/Home";
import ImpressumPage from "pages/Impressum";
import { Switch, Route } from "react-router-dom";

const RouteSwitch: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/">
        <HomePage />
      </Route>
      <Route exact path="/camera">
        <CameraPage />
      </Route>
      <Route exact path="/impressum">
        <ImpressumPage />
      </Route>
      <Route exact path={`/${process.env.REACT_APP_CONTENT_FOLDER}`}>
        <ContentRouter />
      </Route>
      <Route exact path={`/${process.env.REACT_APP_CONTENT_FOLDER}/:id`}>
        <ContentPage />
      </Route>
      <Route>
        <HomePage />
      </Route>
    </Switch>
  );
};

export default RouteSwitch;
