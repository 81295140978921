import { ReactNode } from "react";
import clsx from "clsx";

interface Props {
  children: ReactNode;
  className?: string;
  color?: string;
  style?: Record<string, unknown>;
}

const Subtitle: React.FC<Props> = ({
  children,
  className,
  color = "text-primary-main",
  style,
}: Props) => {
  return (
    <h6
      className={clsx("font-light signika", color, className)}
      style={{
        // fontSize: 30,
        ...style,
      }}
    >
      {children}
    </h6>
  );
};

export default Subtitle;
